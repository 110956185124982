














import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { RootGetter } from '@/features/core/store';
import delay from '@/util/delay';
import { Component, Mixins } from 'vue-property-decorator';
import {
  AppMaintenanceAppAlertsViewQuery,
  AppMaintenanceAppAlertsViewQueryVariables,
} from './__generated__/AppMaintenanceAppAlertsViewQuery';
import {
  AppMaintenanceAppAlertsResetAlertMutation,
  AppMaintenanceAppAlertsResetAlertMutationVariables,
} from './__generated__/AppMaintenanceAppAlertsResetAlertMutation';
import resetAlertMutation from './reset-alert.gql';
import query from './view.gql';

@Component({
  apollo: {
    collection: {
      query,
      pollInterval: 60000,
      variables(this: AlertsView): AppMaintenanceAppAlertsViewQueryVariables {
        return { take: this.take, skip: this.skip };
      },
    },
  },
  data() {
    return { collection: undefined };
  },
})
export default class AlertsView extends Mixins(PaginationQueryStringMixin) {
  private readonly collection?: AppMaintenanceAppAlertsViewQuery['collection'];

  @RootGetter
  private readonly admin!: boolean;

  protected get more(): boolean {
    return this.collection?.more ?? false;
  }

  private async onResetAlert(alert: AppMaintenanceAppAlertsViewQuery['collection']['items'][number]): Promise<void> {
    if (alert.ruleDeployment === null || alert.spot === null) {
      return;
    }

    await this.$apollo.mutate<
      AppMaintenanceAppAlertsResetAlertMutation,
      AppMaintenanceAppAlertsResetAlertMutationVariables
    >({
      mutation: resetAlertMutation,
      variables: {
        input: { spotId: alert.spot.id, ruleDeploymentId: alert.ruleDeployment.id },
      },
    });

    await delay(10000);

    await this.$apollo.queries.collection.refetch();
  }
}
